import { render, staticRenderFns } from "./Correction.vue?vue&type=template&id=62a2e9aa&scoped=true&"
import script from "@/assets/js/teacher/report/correction.js?vue&type=script&lang=js&"
export * from "@/assets/js/teacher/report/correction.js?vue&type=script&lang=js&"
import style0 from "@/assets/css/teacher/report/correction.scss?vue&type=style&index=0&id=62a2e9aa&prod&lang=scss&scoped=true&"
import style1 from "./Correction.vue?vue&type=style&index=1&id=62a2e9aa&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62a2e9aa",
  null
  
)

export default component.exports